@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* important for the homepage design */
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/** TABLES WITH SCROLL */
table.with-overflow {
  width: 100%;
  border-spacing: 0;
}

table.with-overflow thead,
table.with-overflow tbody,
table.with-overflow tr,
table.with-overflow th,
table.with-overflow td {
  display: block;
}

table.with-overflow thead tr {
  /* fallback */
  width: 97%;
  /* minus scroll bar width */
  width: -webkit-calc(100% - 16px);
  width: -moz-calc(100% - 16px);
  width: calc(100% - 16px);
}

table.with-overflow tr:after {
  /* clearing float */
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}

table.with-overflow tbody {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

table.with-overflow tbody td,
table.with-overflow thead th {
  width: 19%; /* 19% is less than (100% / 5 cols) = 20% */
  width: calc(100% / 8);
  float: left;
}

/* DEACTIVATE disable styles from SUI */
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  pointer-events: none;
  opacity: 0.8 !important;
  color: black;
}

.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  opacity: 0.8 !important;
  color: black;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 0.8 !important;
  color: black;
}

.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  cursor: default;
  color: black;
  opacity: 0.8 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
  user-select: none !important;
}
